import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';

import Layout from 'shared/AnotherCourses/Layout';
import { HeaderWithPromotion } from 'shared/AnotherCourses/component/HeaderPage';
import MySEO from 'shared/MySEO';
import { useBreadcrumbSchema } from 'shared/AnotherCourses/hooks/useBreadcrumbSchema';

const ListCourse = ({ location }) => {
  const breadCrumbList = useBreadcrumbSchema({
    slug: 'tieng-anh-giao-tiep',
    heading: 'Tiếng Anh giao tiếp'
  });

  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        slug={location.pathname}
        breadCrumbSchema={breadCrumbList}
        h1="Tiếng anh giao tiếp"
        featureImg="1OJijgZvTxGXcbGHiNla"
        title="Tổng hợp khóa học tiếng anh giao tiếp cho người đi làm"
        description="Khóa học tiếng Anh giao tiếp dành cho ngừời đi làm, khóa học tiếng Anh giao tiếp online uy tín, hiệu quả, chuyên nghiệp nhất tại Tp. HCM"
      />
      <HeaderWithPromotion level="Tiếng Anh giao tiếp" />
      <Layout level="Khóa TA giao tiếp" />
    </MainLayout>
  );
};

export default React.memo(ListCourse);
